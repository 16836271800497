























import { Component, Prop, Vue } from "vue-property-decorator";
import Currency from '@/components/Currency.vue'
import { RedCapital, SelectedCountry, TitleTemplatePublic } from '@/configuration'

@Component({
  metaInfo: {
    title: 'Gracias',
    titleTemplate: TitleTemplatePublic,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang,
    }
  },
})

export default class Decline extends Vue {}
